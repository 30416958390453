import React, { Component } from "react";
import { Layout } from "antd";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage";
import IntelligencePage from "./pages/IntelligencePage";

const {
  Content
} = Layout;


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastfilter: null
    };


  }


  render() {
    
    const RootRouter = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={props => (
          <Redirect to={{ pathname: "/inteligencia"}} />
        )}
      />
    );

    return (
      <BrowserRouter>
        <Layout style={{ minHeight: "100vh", padding: 0 }}>
          <Layout>
            <Content className="margin-content">
              <Switch>
                <RootRouter exact path="/"component={IntelligencePage} />
                <Route path="/inteligencia" component={IntelligencePage} />
                <Route  component={NotFoundPage} />
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </BrowserRouter>
    );
  }
}

export default App;