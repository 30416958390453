import React, { Component } from 'react';
import Iframe from 'react-iframe'
import { Row } from "antd";
import {
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    FacebookShareButton,
    FacebookIcon,
    EmailShareButton,
    EmailIcon
  } from "react-share";

class IntelligencePage extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <Row>
                    <Iframe url="https://app.powerbi.com/view?r=eyJrIjoiNzI2NTZmMGQtY2I4ZS00MWY3LWIxYmUtNGJjYWY0YWQ2ZWRmIiwidCI6IjM5MTFjN2IwLTk1ZTEtNDBkYS1hMWNkLTdlYjRkNDNmOGYxZCJ9"
                        width="100%"
                        height="100%"
                        className="custom-frame"
                        id="myFrame"
                        display="initial"
                        position="relative" />
                </Row>
                

                <footer>
                        <p>Powered by <a href="https://anestech.com.br/" rel="noopener noreferrer" target="_blank">Anestech Innovation Rising - 2020</a> 
                            <span>
                                <LinkedinShareButton url="https://anes.tech/inteligencia"><LinkedinIcon size={32} round={true} /></LinkedinShareButton> &nbsp;
                                <TwitterShareButton  url="https://anes.tech/inteligencia"><TwitterIcon size={32} round={true} /></TwitterShareButton>&nbsp;
                                <FacebookShareButton  url="https://anes.tech/inteligencia"><FacebookIcon size={32} round={true} /></FacebookShareButton>&nbsp;
                                <EmailShareButton  url="https://anes.tech/inteligencia"><EmailIcon size={32} round={true} /></EmailShareButton>&nbsp;

                            </span>
                        </p>
                    </footer>
            </div>
        );
    }
}

export default IntelligencePage;